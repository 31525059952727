<!-- eslint-disable vue/no-template-key -->
<template>
    <div style="display: flex; flex-direction: column; justify-content: center; align-items: center;">
        <div class="a4" ref="printPaperRef" id="printPaperRef">
            <div class="page-header">
                <div>
                    <img :src="require('@/assets/logo.svg')" />
                </div>
                <div>
                    <span class="company">海南小紫医疗科技有限公司</span>
                </div>
            </div>
            <div class="title1">维修服务报价函</div>
            <table border="1" style="width: 100%;">
                <tbody>
                    <tr>
                        <td colspan="5">
                            <span>甲方(服务接收方)：{{ contractData.partA.name }}</span><br />
                            <span>地址：{{ contractData.partA.address }}</span><br />
                            <span>联系人：</span><br />
                            <span>电话：{{ contractData.partA.telephone }}</span><br />
                            <span>传真：{{ contractData.partA.fax }}</span><br />
                            <span>邮箱：</span>
                        </td>
                        <td colspan="4">
                            <span>乙方（服务提供方）：{{ contractData.partB.name }}</span><br />
                            <span>地址：{{ contractData.partB.address || '' }}</span><br />
                            <span>联系人：</span><br />
                            <span>电话：{{ contractData.partB.telephone || '' }}</span><br />
                            <span>传真：{{ contractData.partB.fax || '' }}</span><br />
                            <span>&emsp;</span>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="9">
                            设备信息
                        </td>
                    </tr>
                    <tr>
                        <td>
                            序号
                        </td>
                        <td colspan="2">
                            设备名称
                        </td>
                        <td colspan="2">
                            设备型号
                        </td>
                        <td colspan="2">
                            设备品牌
                        </td>
                        <td colspan="2">
                            设备序列号
                        </td>
                    </tr>
                    <tr>
                        <td rowspan="2">1</td>
                        <td colspan="2">{{ contractData.deviceList[0].deviceName }}</td>
                        <td colspan="2">{{ contractData.deviceList[0].deviceModel }}</td>
                        <td colspan="2">{{ contractData.deviceList[0].deviceBrandName }}</td>
                        <td colspan="2">{{ contractData.deviceList[0].deviceSerialNumber }}</td>
                    </tr>
                    <tr>
                        <td colspan="2">
                            故障描述
                        </td>
                        <td colspan="6">
                            <div>{{ contractData.deviceList[0].faultDescription }}</div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="9">
                            计费清单
                        </td>
                    </tr>
                    <tr>
                        <td>
                            序号
                        </td>
                        <td>
                            计费项目
                        </td>
                        <td>
                            计费项名称
                        </td>
                        <td>
                            零备件号
                        </td>
                        <td>
                            含税单价(元)
                        </td>
                        <td>
                            数量
                        </td>
                        <td>
                            税率
                        </td>
                        <td>
                            质保期/保修期
                        </td>
                        <td>
                            含税金额(元)
                        </td>
                    </tr>
                    <tr v-for="d in contractData.billList" :key="d.index">
                        <td>{{ d.index }}</td>
                        <td>{{ d.typeName }}</td>
                        <td>{{ d.name }}</td>
                        <td>{{ d.deviceRegisterCode }}</td>
                        <td>{{ d.taxPrice }}</td>
                        <td>{{ d.quantity }}</td>
                        <td>{{ d.taxRate }}</td>
                        <td>{{ d.warrantyPeriod }} {{ d.warrantyPeriodUnit }}</td>
                        <td>{{ d.taxAmount }}</td>
                    </tr>
                    <tr>
                        <td colspan="9">
                            合计： {{ contractData.billTotal }}元, 大写： {{ contractData.chineseBillTotal }}
                        </td>
                    </tr>
                    <tr>
                        <td colspan="9">
                            <span>1、以上零备件价格为新旧备件交换价格，维修后所替换下的旧备件归乙方所有；如甲方保留旧备件，备件价格需另外增加30%。本确认函中的报价在报价日期之日起____天内有效。</span><br />
                            <span>2、其他：乙方收到全款后____个工作日内提供服务；如实际维修过程中需要更换其他备件，经双方确认后根据实际情况计算费用。</span><br />
                            <span>3、双方因服务履行产生争议应先行友好协商，协商不成的由乙方所在地人民法院管辖。</span>
                        </td>
                    </tr>
                    <tr v-for="(payment, index) in contractData.paymentList" :key="index">
                        <td colspan="1">
                            付款
                        </td>
                        <td colspan="8">
                            甲方应于{{ payment.paymentDateArray[0] }}年{{ payment.paymentDateArray[1] }}月{{
                                payment.paymentDateArray[2] }}日前将全款支付至乙方账户，乙方收到甲方款项后____个工作日内提供发票。
                        </td>
                    </tr>
                    <tr>
                        <td colspan="1">
                            账户
                        </td>
                        <td colspan="2">
                            {{ contractData.partB.name }}
                        </td>
                        <td colspan="1">
                            账号
                        </td>
                        <td colspan="2">
                            {{ contractData.bankAccount }}
                        </td>
                        <td colspan="1">
                            开户银行
                        </td>
                        <td colspan="2">
                            {{ contractData.bankName }}
                        </td>
                    </tr>
                    <tr>
                        <td colspan="1">
                            <div>说明</div>
                        </td>
                        <td colspan="8">
                            如贵方接受维修服务报价，请完整填写甲方信息后，将签字盖章的本报价函传真/扫描至乙方，该函件至我司时即为对双方有约束力的法律凭证。
                        </td>
                    </tr>
                    <tr>
                        <td colspan="5">
                            <span>甲方名称：</span><br />
                            <span>（盖章）</span><br />
                            <span>负责人授权代表：</span><br />
                            <span>（盖章）</span><br />
                            <span>签字日期： &emsp;&emsp;&emsp;&emsp;年&emsp;&emsp;月&emsp;&emsp;日</span>
                        </td>
                        <td colspan="4">
                            <span>乙方名称：</span><br />
                            <span>（盖章）</span><br />
                            <span>负责人授权代表：</span><br />
                            <span>（盖章）</span><br />
                            <span>签字日期： &emsp;&emsp;&emsp;&emsp;年&emsp;&emsp;月&emsp;&emsp;日</span>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div style="text-align: center;">
            <a-button @click="handlePrint">打印</a-button>
        </div>
    </div>
</template>
<script>
export default {
  name: 'QuotationLetterContract',
  props: {
    contractData: {
      type: Object,
      default: () => ({}),
    },
  },
  data () {
    return {};
  },
  methods: {
    handlePrint () {
      const style = '@page {margin:6mm 20mm;size: A4;} @charset "UTF-8";body {font-family: "宋体";}.a4 {width: 210mm;background-color: #fff;}.a4 .page-header {display: flex;justify-content: space-between;align-items: flex-end;font-size: 12px;padding-bottom: 6px;border-bottom: 1px solid #000;}.a4 .page-header img {width: 62%;}.a4 .page-header .company {display: inline-block;width: 16em;color: #808080;}.a4 .title1 {text-align: center;line-height: 60px;font-size: 22px;letter-spacing: .5em;}.a4 table {width: 99%;table-layout: fixed;border-collapse: collapse;}.a4 table tr {page-break-inside: avoid;}.a4 table td {word-break: break-all;padding: 8px;}';

      this.$print({
        printable: 'printPaperRef',
        type: 'html',
        targetStyles: ['*'],
        style,
        scanStyles: false,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.a4 {
    width: 210mm;
    background-color: #fff;

    .page-header {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        font-size: 12px;
        padding-bottom: 6px;
        border-bottom: 1px solid #000;

        img {
            width: 62%;
        }

        .company {
            display: inline-block;
            width: 16em;
            color: #808080;
        }
    }

    tr {
        page-break-inside: avoid;
    }

    .title1 {
        text-align: center;
        line-height: 32px;
        font-size: 20px;
        font-weight: bold;
        letter-spacing: .5em;
    }

    table {
        width: 99%;
        table-layout: fixed;
        border-collapse: collapse;

        tr {
            page-break-inside: avoid;
        }

        td {
            word-break: break-all;
            padding: 8px;
        }
    }
}
</style>

