<template>
  <div style="display: flex; flex-direction: column; justify-content: center; align-items: center;">
    <div class="a4" ref="printPaperRef" id="printPaperRef">
      <div class="page-header">
        <div>
          <img :src="require('@/assets/logo.svg')" />
        </div>
        <div>
          <span class="company">海南小紫医疗科技有限公司</span>
        </div>
      </div>
      <div class="title1">产品技术服务合同</div>
      <table border="1" class="dashed">
        <tr>
          <td colspan="2">甲方（服务接收方）：</td>
          <td colspan="3">{{ contractData.partA.name }}</td>
          <td colspan="3">最终用户：{{ contractData.partA.finalCustomerName || '' }}</td>
        </tr>
        <tr>
          <td colspan="8">法人代表：{{ contractData.partA.legalPerson || '' }}</td>
        </tr>
        <tr>
          <td colspan="5">&emsp;&emsp;地址：{{ contractData.partA.address }}</td>
          <td colspan="3">邮编：{{ contractData.partA.postCode }}</td>
        </tr>
        <tr>
          <td colspan="5">&emsp;&emsp;电话：{{ contractData.partA.telephone }}</td>
          <td colspan="3"></td>
        </tr>
        <tr>
          <td colspan="5">&emsp;&emsp;传真：{{ contractData.partA.fax }}</td>
          <td colspan="3"></td>
        </tr>
        <tr>
          <td colspan="2">乙方（服务提供方）：</td>
          <td colspan="6">{{ contractData.partB.name }}</td>
        </tr>
        <tr>
          <td colspan="8">法人代表：{{ contractData.partB.legalPerson || '' }}</td>
        </tr>
        <tr>
          <td colspan="5">&emsp;&emsp;地址：{{ contractData.partB.address || '' }}</td>
          <td colspan="3">邮编：{{ contractData.partB.postCode }}</td>
        </tr>
        <tr>
          <td colspan="5">&emsp;&emsp;电话：{{ contractData.partB.telephone || '' }}</td>
          <td colspan="3"></td>
        </tr>
        <tr>
          <td colspan="5">&emsp;&emsp;传真：{{ contractData.partB.fax || '' }}</td>
          <td colspan="3"></td>
        </tr>
      </table>
      <div class="gap-top-2em indent-2em">甲乙双方经过平等协商，达成本产品技术服务合同（以下简称“合同”），合同内容包括具体条款、附件及合同中提及的其他文件。</div>
      <div class="gap-top-2em title2">1.&emsp;服务内容：</div>
      <div class="indent-2em">1.1&ensp;维修服务所服务设备：</div>
      <table border="1">
        <thead>
          <tr>
            <th style="width:50px;">序号</th>
            <th>设备名称</th>
            <th>设备型号</th>
            <th>装机日期</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(d, index) in contractData.deviceList" :key="`device${index}`">
            <td>{{ index + 1 }}</td>
            <td>{{ d.deviceName }}</td>
            <td>{{ d.deviceModel }}</td>
            <td>{{ d.installationDate }}</td>
          </tr>
        </tbody>
      </table>
      <div class="gap-top-1em indent-2em"><span style="position: absolute;">1.2&ensp;</span>
        <div style="margin-left: 2.5em;display: inline-block;">技术服务期限为{{ contractData.yearsOfService }}年，即自{{ contractData.effectiveDate[0] }}年{{
          contractData.effectiveDate[1] }}月{{ contractData.effectiveDate[2] }}日 至
          {{ contractData.terminalDate[0] }}年{{ contractData.terminalDate[1] }}月{{ contractData.terminalDate[2] }}日。</div>
      </div>
      <div class="gap-top-1em indent-2em"><span style="position: absolute;">1.3&ensp;</span>
        <div style="margin-left: 2.5em;display: inline-block;">服务地址:{{ contractData.partA.address || '/' }}</div>
      </div>
      <div class="gap-top-2em title2">2.&emsp;技术服务费及支付：</div>
      <div class="indent-2em"><span style="position: absolute;">2.1&ensp;</span>
        <div style="margin-left: 2.5em;display: inline-block;">付款方式：(合同款项结算以银行转账相关凭证为准)</div>
      </div>
      <div class="indent-2em"><span style="position: absolute;">2.1.1&ensp;</span>
        <div style="margin-left: 2.5em;display: inline-block;">合同费用总计：{{ contractData.totalAmount
        }}，大写{{ contractData.chineseTotalAmount }}</div>
      </div>
      <div class="indent-2em">以上价款{{ contractData.taxRate ? '' : '未' }}含税，税率为{{contractData.taxRate || 0}}，税费为：{{ contractData.taxes }} ，税费由 甲 方承担。</div>
      <div v-if="contractData.paymentList.length === 1" class="indent-2em">合同签署后{{ contractData.paymentDays
      }}日内一次性支付全部价款。甲方应当按照合同约定将合同款项支付至交付乙方指定账户。</div>
      <template v-else>
        <div class="indent-2em">甲方按照如下约定分{{ contractData.paymentList.length || '' }}期向乙方支付合同款项：</div>
        <div v-for="(payment, index) in contractData.paymentList" class="indent-2em" :key="index">
          第{{ payment.index }}期：在{{ payment.paymentDateArray[0] }}年{{ payment.paymentDateArray[1] }}月{{
            payment.paymentDateArray[2] }}日前支付人民币{{ payment.paymentAmount
    || '' }}元；</div>
      </template>
      <div class="indent-2em title2">乙方账户：</div>
      <div class="indent-2em title2">名 称：{{ contractData.partB.name }}</div>
      <div class="indent-2em title2">账 号：{{ contractData.bankAccount }}</div>
      <div class="indent-2em title2">开户行：{{ contractData.bankName }}</div>
      <div class="indent-2em">2.2&ensp;发票：{{ contractData.invoiceTypeName }}</div>
      <div class="indent-2em">乙方收到甲方全款后5个工作日内向甲方开具合规的等值发票。</div>
      <div class="gap-top-2em title2">3.&emsp;甲、乙方权利与义务：</div>
      <div class="indent-2em"><span style="position: absolute;">3.1&ensp;</span>
        <div style="margin-left: 2.5em;display: inline-block;">甲方权利与义务。</div>
      </div>
      <div class="indent-2em"><span style="position: absolute;">（1）&ensp;</span>
        <div style="margin-left: 2.5em;display: inline-block;">为乙方提供后勤保障方面的便利条件。</div>
      </div>
      <div class="indent-2em"><span style="position: absolute;">（2）&ensp;</span>
        <div style="margin-left: 2.5em;display: inline-block;">甲方按时足额向乙方支付技术服务费。</div>
      </div>
      <div class="indent-2em"><span style="position: absolute;">（3）&ensp;</span>
        <div style="margin-left: 2.5em;display: inline-block;">甲方应当根据乙方技术培训的要求正确使用设备。</div>
      </div>
      <div class="indent-2em"><span style="position: absolute;">（4）&ensp;</span>
        <div style="margin-left: 2.5em;display: inline-block;">甲方应当妥善管理、保管设备，指派专人负责设备运营等日常工作。</div>
      </div>
      <div class="indent-2em"><span style="position: absolute;">（5）&ensp;</span>
        <div style="margin-left: 2.5em;display: inline-block;">甲方从事的一切行为均不得损坏设备件，不得阻碍或改变设备件原来的用途和功能。</div>
      </div>
      <div class="indent-2em"><span style="position: absolute;">3.2&ensp;</span>
        <div style="margin-left: 2.5em;display: inline-block;">乙方权利与义务。</div>
      </div>
      <div class="indent-2em"><span style="position: absolute;">（1）&ensp;</span>
        <div style="margin-left: 2.5em;display: inline-block;">乙方负责设备的技术及咨询服务。</div>
      </div>
      <div class="indent-2em"><span style="position: absolute;">（2）&ensp;</span>
        <div style="margin-left: 2.5em;display: inline-block;">乙方负责为设备的使用提供必要的技术资料。</div>
      </div>
      <div class="indent-2em"><span style="position: absolute;">（3）&ensp;</span>
        <div style="margin-left: 2.5em;display: inline-block;">乙方负责提供每年{{contractData.secondPartyYearServiceTimes}}次设备的集中技术培训，具体的培训内容及时间由甲、乙双方协商决定。</div>
      </div>
      <div class="indent-2em"><span style="position: absolute;">（4）&ensp;</span>
        <div style="margin-left: 2.5em;display: inline-block;">乙方负责设备日常技术及咨询服务。</div>
      </div>
      <div class="gap-top-2em title2">4.&emsp;违约责任：</div>
      <div class="indent-2em"><span style="position: absolute;">4.1&ensp;</span>
        <div style="margin-left: 2.5em;display: inline-block;">
          甲方延迟支付技术服务费的，每延付一日，按应付未付金额的万分之五向乙方支付违约金。</div>
      </div>
      <div class="indent-2em"><span style="position: absolute;">4.2&ensp;</span>
        <div style="margin-left: 2.5em;display: inline-block;">
          甲方存在违反本合同下列任一情形，乙方有权按照本合同第四条第3款的约定采取下列一种或多种措施。</div>
      </div>
      <div class="indent-3em"><span style="position: absolute;">4.2.1&ensp;</span>
        <div style="margin-left: 2.5em;display: inline-block;">
          甲方未按时、足额支付乙方任一期技术服务费或本合同项下其他款项或者逾期履行本协议其他义务超过{{contractData.firstPartyOverduePaymentDay}}天；</div>
      </div>
      <div class="indent-3em"><span style="position: absolute;">4.2.2&ensp;</span>
        <div style="margin-left: 2.5em;display: inline-block;">
          甲方发生分立、合并、兼并、转制、搬迁、更名或其他商业行为，或实际控制人发生变化，或甲方停止经营任何主要部分的业务，或甲方提出或者被提出有关诉讼、破产、歇业等，乙方认为该等情况将导致本协议的无法正常履行的；</div>
      </div>
      <div class="indent-3em"><span style="position: absolute;">4.2.3&ensp;</span>
        <div style="margin-left: 2.5em;display: inline-block;">
          甲方或指定设备管理使用方私自处理设备，包括但不限于变更存放地点、私自拆分（更换）任何不符合国家、行业标准、【产品说明书】或与设备参数不相匹配的零部件、改变设备现状或不及时配合财务监督或提供虚假财务结算依据及其他违反本合同约定的；
        </div>
      </div>
      <div class="indent-3em"><span style="position: absolute;">4.2.4&ensp;</span>
        <div style="margin-left: 2.5em;display: inline-block;">
          甲方、甲方关键岗位人员或指定设备管理使用方已被或将被行政或司法等处罚，乙方认为该等情况将导致本合同的无法正常履行的；
        </div>
      </div>
      <div class="indent-3em"><span style="position: absolute;">4.2.5&ensp;</span>
        <div style="margin-left: 2.5em;display: inline-block;">
          设备灭失或毁损到经双方认定无法修理的程度时；
        </div>
      </div>
      <div class="indent-3em"><span style="position: absolute;">4.2.6&ensp;</span>
        <div style="margin-left: 2.5em;display: inline-block;">
          甲方没有履行本合同任何其他义务或责任；
        </div>
      </div>
      <div class="indent-3em"><span style="position: absolute;">4.2.7&ensp;</span>
        <div style="margin-left: 2.5em;display: inline-block;">
          乙方认为甲方履行本合同能力明显降低或者丧失履行能力的。
        </div>
      </div>
      <div class="indent-2em"><span style="position: absolute;">4.3&ensp;</span>
        <div style="margin-left: 2.5em;display: inline-block;">
          如果在本合同签署后任何时间存在本合同第四条第2款约定的任一情形的，乙方有权采取下列一种或多种措施：
        </div>
      </div>
      <div class="indent-3em"><span style="position: absolute;">4.3.1&ensp;</span>
        <div style="margin-left: 2.5em;display: inline-block;">
          要求甲方在收到乙方通知之日起5个工作日内付清本合同项下全部到期和未到期技术服务费、违约金、利息、损害赔偿金、实现债权的费用（包括但不限于诉讼费用、律师费用、公证费用、执行费用、财产保全费、催告费、通知费等）等全部费用，并赔偿乙方所遭受的实际经济损失，甲方未按乙方要求支付加速到期全部款项的，乙方有权取回并处置设备；
        </div>
      </div>
      <div class="indent-3em"><span style="position: absolute;">4.3.2&ensp;</span>
        <div style="margin-left: 2.5em;display: inline-block;">
          解除本协议，并停止对甲方的技术服务，并要求甲方赔偿乙方所遭受的损失；
        </div>
      </div>
      <div class="indent-3em"><span style="position: absolute;">4.3.3&ensp;</span>
        <div style="margin-left: 2.5em;display: inline-block;">
          要求甲方承担乙方就甲方违约行为行使任何权利所发生的实现债权的费用（包括但不限于诉讼费用、律师费用、公证费用、执行费用、财产保全费、催告费、通知费等）及其他合理支出及费用等，同时甲方还应配合进行相关工作并提供相应文件；
        </div>
      </div>
      <div class="indent-3em"><span style="position: absolute;">4.3.4&ensp;</span>
        <div style="margin-left: 2.5em;display: inline-block;">
          乙方在采取前款规定的处置方法时，并不免除本合同约定的甲方所应承担的义务；
        </div>
      </div>
      <div class="indent-3em"><span style="position: absolute;">4.3.5&ensp;</span>
        <div style="margin-left: 2.5em;display: inline-block;">
          如乙方通过上述措施及在本合同项下所收到的款项（包括保证金）不足以清偿甲方所欠乙方全部款项时，甲方应根据乙方指定的方法和顺序偿还债务。
        </div>
      </div>
      <div class="gap-top-2em title2">5.&emsp;合同生效：</div>
      <div class="indent-2em">本合同经甲、乙双方签字盖章后生效。任何补充部分经双方书面签署后具有同样的法律效力。</div>
      <div class="gap-top-2em title2">6.&emsp;合同变更：</div>
      <div class="indent-2em"><span style="position: absolute;">6.1&ensp;</span>
        <div style="margin-left: 2.5em;display: inline-block;">
          本合同在技术服务期限届满时终止。</div>
      </div>
      <div class="indent-2em"><span style="position: absolute;">6.2&ensp;</span>
        <div style="margin-left: 2.5em;display: inline-block;">
          甲、乙双方因本合同的履行产生的债权债务关系应自违约金、损害赔偿金等全部债务清偿完毕后终止。</div>
      </div>
      <div class="gap-top-2em title2">7.&emsp;反商业贿赂：</div>
      <div class="indent-2em"><span style="position: absolute;">7.1&ensp;</span>
        <div style="margin-left: 2.5em;display: inline-block;">
          双方应遵守国家及地方现行有效的法律法规，包括但不限于《反不正当竞争法》及国家工商行政管理局颁发的《关于禁止商业贿赂行为的暂行规定》，不得从事任何有损产品或乙方商业形象的行为，并且应遵守各项规定。</div>
      </div>
      <div class="indent-2em"><span style="position: absolute;">7.2&ensp;</span>
        <div style="margin-left: 2.5em;display: inline-block;">任何一方均应保留能够证明其遵守本条规定的真实准确的记录，经对方要求，应详细说明遵守本条规定的情况。</div>
      </div>
      <div class="gap-top-2em title2">8.&emsp;其它：</div>
      <div class="indent-2em"><span style="position: absolute;">8.1&ensp;</span>
        <div style="margin-left: 2.5em;display: inline-block;">
          本合同有效期内，乙方有权提前10日书面通知甲方要求将本合同项下的全部权利义务转让给乙方指定关联方并变更本合同签订主体，甲方应予以配合办理。本合同有效期内，在不影响甲方正常使用的情况下，乙方有权将其技术服务费收益权转让给其指定第三方，甲方应保证对设备技术服务费收益权转让的认可并积极配合。甲方应自收到乙方书面转让通知之日起5日内就前述转让与乙方和该关联方或第三方签署相关转让协议，该文件签署后，甲方应向该关联方或第三方履行其在本合同项下付款义务。如前述债权转移对甲方造成损失的，乙方应负责弥补甲方的经济损失。
        </div>
      </div>
      <div class="indent-2em"><span style="position: absolute;">8.2&ensp;</span>
        <div style="margin-left: 2.5em;display: inline-block;">未经乙方事先书面同意，甲方不得向任何第三方转让本协议项下的任何权利及义务。</div>
      </div>
      <div class="indent-2em"><span style="position: absolute;">8.3&ensp;</span>
        <div style="margin-left: 2.5em;display: inline-block;">本合同正本一式两份，甲方执一份，乙方执一份。</div>
      </div>
      <div class="indent-2em"><span style="position: absolute;">8.4&ensp;</span>
        <div style="margin-left: 2.5em;display: inline-block;">
          本合同中未明确事项由双方另行约定。本合同的各种附件、附表及附件、附表中所规定的附带条款，均为本合同不可分割的一部分，与本合同具有同等的法律效力。</div>
      </div>
      <div class="indent-2em"><span style="position: absolute;">8.5&ensp;</span>
        <div style="margin-left: 2.5em;display: inline-block;">本合同的效力具有独立性，不因与该合同相关的合同、附件的效力而效力减等、无效等。</div>
      </div>
      <div class="indent-2em"><span style="position: absolute;">8.6&ensp;</span>
        <div style="margin-left: 2.5em;display: inline-block;">若双方就本合同的履行产生争议，双方应本着友好协商的原则解决争议。如协商不成，应提交乙方主要经营地法院诉讼解决。
        </div>
      </div>
      <table border="1" class="gap-top-2em dashed">
        <tr height="150">
          <td class="title2">甲&emsp;&emsp;方：<br />（盖章）</td>
          <td></td>
          <td class="title2">乙&emsp;&emsp;方：<br />（盖章）</td>
          <td></td>
        </tr>
        <tr height="150">
          <td class="title2">授权代表：<br />（签字）</td>
          <td></td>
          <td class="title2">授权代表：<br />（签字）</td>
          <td></td>
        </tr>
        <tr height="150">
          <td class="title2">职&emsp;&emsp;位：</td>
          <td></td>
          <td class="title2">职&emsp;&emsp;位：</td>
          <td></td>
        </tr>
        <tr height="150">
          <td class="title2">日期：</td>
          <td></td>
          <td class="title2">日期：</td>
          <td></td>
        </tr>
      </table>
    </div>
    <div style="text-align: center;">
      <a-button @click="handlePrint">打印</a-button>
    </div>
  </div>
</template>
<script>
export default {
  name: 'WarrantyContract',
  props: {
    contractData: {
      type: Object,
      default: () => ({}),
    },
  },
  data () {
    return {};
  },
  methods: {
    handlePrint () {
      const style = '@page {margin:6mm 20mm;size: A4;} @charset "UTF-8";body {font-family: "宋体";}.a4 {width: 210mm;background-color: #fff;}.a4 .page-header {display: flex;justify-content: space-between;align-items: flex-end;font-size: 12px;padding-bottom: 6px;border-bottom: 1px solid #000;}.a4 .page-header img {width: 62%;}.a4 .page-header .company {display: inline-block;width: 16em;color: #808080;}.a4 div {line-height: 2em;}.a4 .gap-top-1em {margin-top: 1em;}.a4 .gap-top-2em {margin-top: 2em;}.a4 .title2 {font-weight: bolder;}.a4 .indent-2em {margin-left: 2em;}.a4 .indent-3em {margin-left: 3em;}.a4 .title1 {text-align: center;line-height: 60px;font-size: 22px;letter-spacing: .5em;}.a4 table {width: 99%;table-layout: fixed;border-collapse: collapse;}.a4 table tr {page-break-inside: avoid;}.a4 table th {padding: 8px;font-weight: 500;}.a4 table td {word-break: break-all;padding: 8px;}.a4 table.dashed {border: 1px dashed #c8c8c8;}.a4 table.dashed td {border: 1px dashed #c8c8c8;}';

      this.$print({
        printable: 'printPaperRef',
        type: 'html',
        targetStyles: ['*'],
        style,
        scanStyles: false,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.a4 {
  width: 210mm;
  background-color: #fff;

  .page-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    font-size: 12px;
    padding-bottom: 6px;
    border-bottom: 1px solid #000;

    img {
      width: 62%;
    }

    .company {
      display: inline-block;
      width: 16em;
      color: #808080;
    }
  }

  div {
    line-height: 2em;
  }

  .gap-top-1em {
    margin-top: 1em;
  }

  .gap-top-2em {
    margin-top: 2em;
  }

  .title2 {
    font-weight: bolder;
  }

  .indent-2em {
    margin-left: 2em;
  }

  .indent-3em {
    margin-left: 3em;
  }

  .title1 {
    text-align: center;
    line-height: 32px;
    font-size: 20px;
    font-weight: bold;
    letter-spacing: .5em;
  }

  table {
    width: 99%;
    table-layout: fixed;
    border-collapse: collapse;

    tr {
      page-break-inside: avoid;
    }

    th {
      padding: 8px;
      font-weight: 500;
    }

    td {
      word-break: break-all;
      padding: 8px;
    }
  }

  table.dashed {
    border: 1px dashed #c8c8c8;

    td {
      border: 1px dashed #c8c8c8;
    }
  }
}
</style>

