<template>
  <div style="display: flex; flex-direction: column; justify-content: center; align-items: center">
    <div class="a4" ref="printPaperRef" id="printPaperRef">
      <div class="page-header">
        <div>
          <img :src="require('@/assets/logo.svg')" />
        </div>
        <div>
          <span class="company">海南小紫医疗科技有限公司</span>
        </div>
      </div>
      <div class="title1">医院资产管理合同</div>
      <table border="1" class="dashed">
        <tr>
          <td colspan="2">甲方（需方）：</td>
          <td colspan="5">{{ contractData.partA.name }}</td>
        </tr>
        <tr>
          <td colspan="8">法人代表：{{ contractData.partA.legalPerson || '' }}</td>
        </tr>
        <tr>
          <td colspan="5">&emsp;&emsp;地址：{{ contractData.partA.address }}</td>
          <td colspan="3">邮编：{{ contractData.partA.postCode }}</td>
        </tr>
        <tr>
          <td colspan="5">&emsp;&emsp;电话：{{ contractData.partA.telephone }}</td>
          <td colspan="3"></td>
        </tr>
        <tr>
          <td colspan="5">&emsp;&emsp;传真：{{ contractData.partA.fax }}</td>
          <td colspan="3"></td>
        </tr>
        <tr>
          <td colspan="2">乙方（供方）：</td>
          <td colspan="6">{{ contractData.partB.name }}</td>
        </tr>
        <tr>
          <td colspan="8">法人代表：{{ contractData.partB.legalPerson || '' }}</td>
        </tr>
        <tr>
          <td colspan="5">&emsp;&emsp;地址：{{ contractData.partB.address || '' }}</td>
          <td colspan="3">邮编：{{ contractData.partB.postCode }}</td>
        </tr>
        <tr>
          <td colspan="5">&emsp;&emsp;电话：{{ contractData.partB.telephone || '' }}</td>
          <td colspan="3"></td>
        </tr>
        <tr>
          <td colspan="5">&emsp;&emsp;传真：{{ contractData.partB.fax || '' }}</td>
          <td colspan="3"></td>
        </tr>
      </table>
      <div class="gap-top-2em text-indent-2em">
        甲乙双方经过平等协商，达成本医院资产管理合同(以下简称“合同”)，合同内容包括具体条款、附件及合同中提及的其他文件。
      </div>
      <div class="gap-top-2em title2">1.&emsp;合同期限：</div>
      <div class="text-indent-2em">
        本合同为 {{ contractData.contractLimit || '____' }} 年期合同，合同期限自
        {{ contractData.effectiveDate[0] || '____' }} 年 {{ contractData.effectiveDate[1] || '__' }} 月
        {{ contractData.effectiveDate[2] || '__' }} 日 至 {{ contractData.terminalDate[0] || '____' }} 年
        {{ contractData.terminalDate[1] || '__' }} 月 {{ contractData.terminalDate[2] || '__' }} 日止。
      </div>
      <div class="text-indent-2em">
        该合同在期限内连续执行，本合同项下的其他条款、附件、合同中提及的其他文件及补充协议与此项约定不一致的，以此项为准。
      </div>
      <div class="gap-top-2em title2">2.&emsp;资产管理内容：</div>
      <div class="text-indent-2em">
        本合同所涵盖的由乙方提供资产管理服务的甲方资产设备清单，详见附件
        {{ contractData.assetDevicesAttachment || '________' }}
        。乙方向甲方提供的设备清单内的设备资产管理服务，包括医疗设备维修、保养、巡检等管理服务，具体如下：
      </div>
      <div>
        <span style="position: absolute">2.1</span>
        <div style="margin-left: 2em; display: inline-block">乙方向甲方提供的资产管理维修服务内容具体如下：</div>
      </div>
      <div class="indent-2em">
        <span style="position: absolute">2.1.1</span>
        <div style="margin-left: 3em; display: inline-block">
          对合同签署时设备清单上产权不属于甲方或产权属于甲方但在原厂或第三方保修期内的医疗设备，乙方提供接修、联系厂家、跟踪并且督促厂家及时有效的履行售后服务条款。
        </div>
      </div>
      <div class="indent-2em">
        <span style="position: absolute">2.1.2</span>
        <div style="margin-left: 3em; display: inline-block">
          对合同签署时设备清单上产权属于甲方，并且无保修的医疗设备提供接修、维修、日常维护保养计划建立，实施和管理，提供及时、可靠、优质的售后服务（不含核磁磁体、液氦和消耗品，不含消耗品清单详见附件
          {{ contractData.unconsumableAttachment || '________' }}）。
        </div>
      </div>
      <div class="indent-2em">
        <span style="position: absolute">2.1.3</span>
        <div style="margin-left: 3em; display: inline-block">
          提供多种报修方式，包括手机微信端、手机APP扫码、400服务热线以及工程师手机电话报修。
        </div>
      </div>
      <div>
        <span style="position: absolute">2.2</span>
        <div style="margin-left: 2em; display: inline-block">乙方向甲方提供的资产管理技术服务内容具体如下：</div>
      </div>
      <div class="indent-2em">
        <span style="position: absolute">2.2.1</span>
        <div style="margin-left: 3em; display: inline-block">
          乙方将派驻
          {{ contractData.clause.onSitePersonNum || '__' }}
          名技术人员，为甲方的医疗设备提供保养及巡检，设备保养明细及周期以乙方投标文件为准。
        </div>
      </div>
      <div class="indent-2em">
        <span style="position: absolute">2.2.2</span>
        <div style="margin-left: 3em; display: inline-block">同有效期内乙方运用标准医院资产管理系统提供服务。</div>
      </div>
      <div class="indent-2em">
        <span style="position: absolute">2.2.3</span>
        <div style="margin-left: 3em; display: inline-block">利用后台专家库为甲方资产管理提供远程技术咨询与支持。</div>
      </div>
      <div class="indent-2em">
        <span style="position: absolute">2.2.4</span>
        <div style="margin-left: 3em; display: inline-block">
          对纳入服务范围的医疗设备制作二维码张贴，进行整理、清点、盘查以及设备相关数据录入.
        </div>
      </div>
      <div class="indent-2em">
        <span style="position: absolute">2.2.5</span>
        <div style="margin-left: 3em; display: inline-block">
          提供
          {{ contractData.clause.workReport || '________' }}
          工作报告，报告维修服务工作的数据汇总及分析，同时提供年度服务设备的总结分析报告并向甲方进行汇报。
        </div>
      </div>
      <div class="indent-2em">
        <span style="position: absolute">2.2.6</span>
        <div style="margin-left: 3em; display: inline-block">
          根据甲方的需求，乙方可向甲方提供非强检类医疗设备计量检测和校准服务，但需另行收费。
        </div>
      </div>
      <div>
        <span style="position: absolute">2.3</span>
        <div style="margin-left: 2em; display: inline-block">乙方向甲方提供的软件服务内容具体如下：</div>
      </div>
      <div class="indent-2em">
        <span style="position: absolute">2.3.1</span>
        <div style="margin-left: 3em; display: inline-block">
          运用标准医院资产管理系统并结合甲方实际情形对软件进行调试以适配甲方资产管理需求。
        </div>
      </div>
      <div class="indent-2em">
        <span style="position: absolute">2.3.2</span>
        <div style="margin-left: 3em; display: inline-block">
          根据甲方设备情况，在医院设备管理系统中制定设备保养和巡检计划，并设置服务提醒。
        </div>
      </div>
      <div class="indent-2em">
        <span style="position: absolute">2.3.3</span>
        <div style="margin-left: 3em; display: inline-block">
          在合同有效期间提供标准医院资产管理系统的维护、升级服务。
        </div>
      </div>
      <div>
        <span style="position: absolute">2.4</span>
        <div style="margin-left: 2em; display: inline-block">
          对未出现在设备清单上的医疗设备如需纳入乙方服务范围，应根据本合同第4条、第11.1条计算服务费用及签署协议，否则不属于乙方的服务范围。
        </div>
      </div>
      <div>
        <span style="position: absolute">2.5</span>
        <div style="margin-left: 2em; display: inline-block">
          乙方首次盘点后如发现服务范围中医疗设备在乙方服务开始前已损坏或存在隐患，且维修成本较高，乙方有权拒绝为该设备提供服务或另行收取费用，但不应超过乙方进场后的7
          天。
        </div>
      </div>
      <div class="gap-top-2em title2">3.&emsp;双方的权利义务：</div>
      <div>
        <span style="position: absolute">3.1</span>
        <div style="margin-left: 2em; display: inline-block">甲方的权利义务</div>
      </div>
      <div class="indent-2em">
        <span style="position: absolute">3.1.1</span>
        <div style="margin-left: 3em; display: inline-block">
          甲方可对乙方的资产管理服务进行监督，并敦促乙方的工作进程。
        </div>
      </div>
      <div class="indent-2em">
        <span style="position: absolute">3.1.2</span>
        <div style="margin-left: 3em; display: inline-block">
          甲方应为乙方免费提供必要的工作场地和相关设施，确保乙方具备开展工作的相关条件，具体场地要求见附件
          {{ contractData.siteAttachment || '________' }} 。
        </div>
      </div>
      <div class="indent-2em">
        <span style="position: absolute">3.1.3</span>
        <div style="margin-left: 3em; display: inline-block">
          甲方应为乙方的排查、保养、巡检、维修及相关设备管理工作提供便利，予以积极协助和配合。
        </div>
      </div>
      <div class="indent-2em">
        <span style="position: absolute">3.1.4</span>
        <div style="margin-left: 3em; display: inline-block">
          甲方应按设备使用手册的要求进行操作及日常维护，并保证设备所需电源、水源的正常供应及开启和关闭，设备使用环境的温湿度符合相关技术要求。由于甲方不当使用造成设备不能正常运转或配附件的损坏乙方不承担保修责任。
        </div>
      </div>
      <div class="indent-2em">
        <span style="position: absolute">3.1.5</span>
        <div style="margin-left: 3em; display: inline-block">
          对于合同项目下发生的零备件，如无特殊说明甲方需将换下的原备件退还给乙方。
        </div>
      </div>
      <div class="indent-2em">
        <span style="position: absolute">3.1.6</span>
        <div style="margin-left: 3em; display: inline-block">甲方应按合同约定的方式按时向乙方支付服务费用。</div>
      </div>
      <div class="indent-2em">
        <span style="position: absolute">3.1.7</span>
        <div style="margin-left: 3em; display: inline-block">
          本次合同服务相关的化学品（如酒精，高压油等）以及产生的相关废物，甲方应按照国家相
          关法律法规要求进行管理和处置。
        </div>
      </div>
      <div class="indent-2em">
        <span style="position: absolute">3.1.8</span>
        <div style="margin-left: 3em; display: inline-block">
          甲方需向乙方提供或协助乙方要求医疗器械生产经营企业提供相关设备维护手册、维修手册、软件备份、故障代码表、备件清单、零部件、维修密码等维护维修必需的材料和信息。
        </div>
      </div>
      <div class="indent-2em">
        <span style="position: absolute">3.1.9</span>
        <div style="margin-left: 3em; display: inline-block">
          甲方需向乙方提供过往合作过的设备、零部件供应商及服务商名录。
        </div>
      </div>
      <div>
        <span style="position: absolute">3.2</span>
        <div style="margin-left: 2em; display: inline-block">乙方的权利义务</div>
      </div>
      <div class="indent-2em">
        <span style="position: absolute">3.2.1</span>
        <div style="margin-left: 3em; display: inline-block">
          乙方有权要求甲方按照本合同约定的方式按时支付服务费用。
        </div>
      </div>
      <div class="indent-2em">
        <span style="position: absolute">3.2.2</span>
        <div style="margin-left: 3em; display: inline-block">
          乙方每年对设备清单中生命支持类的设备提供
          {{ contractData.clause.yearMaintenanceTimes || '__' }}
          次的保养，对其它设备将按照生产厂家的相关技术要求执行。乙方应在保养工作执行前一周内和甲方预约具体保养时间，如因甲方原因导致不能执行保养的，乙方对此不承担责任。
        </div>
      </div>
      <div class="indent-2em">
        <span style="position: absolute">3.2.3</span>
        <div style="margin-left: 3em; display: inline-block">
          响应时间：乙方派驻工程师按照甲方工作人员的作息时间进行工作安排，正常工作时间内
          {{ contractData.clause.phoneResponseHour || '__' }} 小时响应，{{
            contractData.clause.sceneResponseHour || '__'
          }}
          小时到达工作现场；正常工作时间外，{{ contractData.clause.notWorkDaySceneResponseHour || '__' }}
          小时到达维修现场；乙方提供的技术服务应在24小时内响应，并提供在线技术支持。
        </div>
      </div>
      <div class="indent-2em">
        <span style="position: absolute">3.2.4</span>
        <div style="margin-left: 3em; display: inline-block">
          乙方保证设备平均开机率为 {{ contractData.clause.averageStartupRate || '__' }} %，即每年停机时间平均不超过
          {{ contractData.clause.yearDownMostWorkDay || '__' }} 个工作日(一年
          {{ contractData.clause.yearWorkDay || '__' }}
          个工作日)，超过一天相对应的设备服务期顺延一天；但提供备用机的情形下不视为停机。凡不在维修范围之内的系统部件损坏而导致的停机，不包括在此条款之内。
        </div>
      </div>
      <div class="indent-2em">
        <span style="position: absolute">3.2.5</span>
        <div style="margin-left: 3em; display: inline-block">
          对该合同保修范围内的设备，乙方协助甲方配合计量部门的工作，行政部门计量、检测、强检及特种设备检测费用不在本合同服务范围内。
        </div>
      </div>
      <div class="gap-top-2em title2">4.&emsp;价款：</div>
      <div class="indent-2em">
        合同签署时设备清单中的设备原值为 {{ contractData.clause.assetAmount }} 亿元，服务费率为
        {{ contractData.clause.serviceRate }} %；
      </div>
      <div class="indent-2em">
        年度服务费用为 {{ contractData.yearServeFee || '____' }} /年 (大写)：
        {{ contractData.yearServeFeeCHN || '____ 元整' }}；
      </div>
      <div class="indent-2em">
        合同总价款为 {{ contractData.totalFee || '____' }} (大写)：{{
          contractData.totalFeeCHN || '____ 元整'
        }}（如乙方进场排查后实际设备清单与合同签署时设备清单不符，应以实际服务设备清单为准计算费用）。
      </div>
      <div class="indent-2em">
        合同履行期间，甲方按照规定报废设备、新出保设备或新增设备且尚未进行价款核算的，则双方根据实际核查的设备情况及双方另行协商确认的价款及时签署补充协议调整价款；如为多年期合同，每一年服务完成后双方应就下一年度的服务设备清单进行更新，涉及价款调整的双方应另行签订补充协议。
      </div>
      <div class="indent-2em">合同分项价款如下：</div>
      <div>4.1&ensp;资产管理维修服务费用：</div>
      <div class="indent-2em">4.1.1&ensp;价款：</div>
      <div class="indent-2em">
        单价（人民币）：￥{{ contractData.repairService.taxPrice || '____' }} /年 ( 大写)：{{
          contractData.repairService.taxPrice
            ? numberTranToUpperCN(contractData.repairService.taxPrice + '')
            : '____ 元整'
        }}
        ；
      </div>
      <div class="indent-2em">
        维修服务费用总价（人民币）：￥{{ contractData.repairService.taxAmount || '____' }} 元( 大写)：{{
          contractData.repairService.taxAmount
            ? numberTranToUpperCN(contractData.repairService.taxAmount + '')
            : '____ 元整'
        }}。
      </div>
      <div class="indent-2em">
        {{
          contractData.repairService.taxRate
            ? `以上价款税率为${contractData.repairService.taxRate}。`
            : `以上价款不含税。`
        }}
      </div>
      <div class="indent-2em">4.1.2&ensp;付款方式</div>
      <div class="indent-2em">
        根据双方约定，如为一年期合同甲方须在
        {{
          contractData.repairServicePaymentPlan.length
            ? contractData.repairServicePaymentPlan[0].payDays[0] || '____'
            : '____'
        }}
        年
        {{
          contractData.repairServicePaymentPlan.length
            ? contractData.repairServicePaymentPlan[0].payDays[1] || '____'
            : '____'
        }}
        月
        {{
          contractData.repairServicePaymentPlan.length
            ? contractData.repairServicePaymentPlan[0].payDays[2] || '____'
            : '____'
        }}
        日前向乙方付清维修服务费用全款。
      </div>
      <div class="indent-2em">如为多年期合同，甲方应按如下时间将当年款项付至乙方帐号。</div>
      <div v-for="plan in contractData.repairServicePaymentPlan" :key="plan.index" class="indent-2em">
        第 {{ plan.index }} 期：在 {{ plan.payDays[0] || '____' }} 年 {{ plan.payDays[1] || '____' }} 月
        {{ plan.payDays[2] || '____' }} 日前付清人民币 {{ plan.paymentAmount || '____' }} 元（维修服务费用总价的{{
          plan.ratio || '____'
        }}%）
      </div>
      <div class="indent-2em">（具体支付费用如有双方签订的补充协议或其他约定从其约定）</div>
      <div>4.2&ensp;资产管理技术服务费用：</div>
      <div class="indent-2em">4.2.1&ensp; 价款：</div>
      <div class="indent-2em">
        单价（人民币）：￥{{ contractData.technologyService.taxPrice || '____' }} /年 ( 大写)：{{
          contractData.technologyService.taxPrice
            ? numberTranToUpperCN(contractData.technologyService.taxPrice + '')
            : '____ 元整'
        }}
        ；
      </div>
      <div class="indent-2em">
        技术服务费用总价（人民币）：￥{{ contractData.technologyService.taxAmount || '____' }} 元( 大写)：
        {{
          contractData.technologyService.taxAmount
            ? numberTranToUpperCN(contractData.technologyService.taxAmount + '')
            : '____ 元整'
        }}。
      </div>
      <div class="indent-2em">
        {{
          contractData.technologyService.taxRate
            ? `以上价款税率为${contractData.technologyService.taxRate}。`
            : `以上价款不含税。`
        }}
      </div>
      <div class="indent-2em">4.2.2&ensp;付款方式</div>
      <div class="indent-2em">
        根据双方约定，如为一年期合同甲方须在
        {{
          contractData.technologyServicePaymentPlan.length
            ? contractData.technologyServicePaymentPlan[0].payDays[0] || '____'
            : '____'
        }}
        年
        {{
          contractData.technologyServicePaymentPlan.length
            ? contractData.technologyServicePaymentPlan[0].payDays[1] || '____'
            : '____'
        }}
        月
        {{
          contractData.technologyServicePaymentPlan.length
            ? contractData.technologyServicePaymentPlan[0].payDays[2] || '____'
            : '____'
        }}
        日前向乙方付清技术服务费用全款。
      </div>
      <div class="indent-2em">如为多年期合同，甲方应按如下时间将当年款项付至乙方帐号。</div>
      <div v-for="plan in contractData.technologyServicePaymentPlan" :key='`技术${plan.index}`' class="indent-2em">
        第 {{ plan.index }} 期：在 {{ plan.payDays[0] || '____' }} 年 {{ plan.payDays[1] || '____' }} 月
        {{ plan.payDays[2] || '____' }} 日前付清人民币 {{ plan.paymentAmount || '____' }} 元（技术服务费用总价的{{
          plan.ratio || '____'
        }}%）
      </div>
      <div class="indent-2em">（具体支付费用如有双方签订的补充协议或其他约定从其约定）</div>
      <div class="indent-2em">4.3&ensp;乙方指定账户信息如下：</div>
      <div class="indent-2em title2">名 称：{{ contractData.partB.name }}</div>
      <div class="indent-2em title2">账 号：{{ contractData.bankAccount }}</div>
      <div class="indent-2em title2">开户行：{{ contractData.bankName }}</div>
      <div class="gap-top-2em title2">5.&emsp;合同的变更和转让：</div>
      <div class="indent-2em">
        合同有效期内,
        任何一方不得擅自变更合同的权利和义务。甲方同意在保证甲方利益的情况下，乙方可以将合同的部分或全部转让给其关联公司或第三方公司。
      </div>
      <div class="gap-top-2em title2">6.&emsp;豁免及限制：</div>
      <div class="indent-2em">本合同不包括下列服务项目(甲方如果要求乙方提供下列服务，则应另行承担费用)</div>
      <div class="indent-2em">
        <span style="position: absolute">6.1</span>
        <div style="margin-left: 2em; display: inline-block">
          医疗设备整机或部分重装、移机、拆机、搬动等相应的费用及保险费用。
        </div>
      </div>
      <div class="indent-2em">
        <span style="position: absolute">6.2</span>
        <div style="margin-left: 2em; display: inline-block">
          非乙方指派、甲方自行或擅自委托他人修理调整机器、换装零件、改装机器及配件，以及其他不受乙方控制的任何原因所造成的设备损坏、故障。
        </div>
      </div>
      <div class="indent-2em">
        <span style="position: absolute">6.3</span>
        <div style="margin-left: 2em; display: inline-block">
          甲方或其代表未遵照保养及操作手册上的程序操作所引起的设备故障或相关设施损坏。
        </div>
      </div>
      <div class="indent-2em">
        <span style="position: absolute">6.4</span>
        <div style="margin-left: 2em; display: inline-block">医疗设备临床医生、技师应用培训或医工人员的技术培训。</div>
      </div>
      <div class="indent-2em">
        <span style="position: absolute">6.5</span>
        <div style="margin-left: 2em; display: inline-block">
          行政部门计量、检测、强检类设备、特种设备检测、非强检类医疗设备计量检测和校准服务。
        </div>
      </div>
      <div class="indent-2em">
        <span style="position: absolute">6.6</span>
        <div style="margin-left: 2em; display: inline-block">
          厂家已经明确停产且停止备件供应，无法购买到零配件的设备维修；设备使用5年以上、维修后仍然无法达到使用标准的设备的维修及医疗器械质控中心规定的其他报废标准的设备的维修。
        </div>
      </div>
      <div class="gap-top-2em title2">7.&emsp;信息保密：</div>
      <div class="indent-2em">
        甲乙双方对本合同及本合同相关的其它信息、涉及乙方知识产权的相关信息均负有保密义务。未经另一方事先书面同意，任何一方不得将该信息的任何部分向任何第三方或社会公众披露。本条款不受本合同有效期的限制。
      </div>
      <div class="gap-top-2em title2">8.&emsp;反腐败条款：</div>
      <div class="indent-2em">
        <span style="position: absolute">8.1&ensp;</span>
        <div style="margin-left: 2em; display: inline-block">
          双方应遵守国家及地方现行有效的法律法规，包括但不限于《反不正当竞争法》及国家工商行政管理局颁发的《关于禁止商业贿赂行为的暂行规定》，不得从事任何有损产品或乙方商业形象的行为，并且应遵守各项规定。
        </div>
      </div>
      <div class="indent-2em">
        <span style="position: absolute">8.2&ensp;</span>
        <div style="margin-left: 2em; display: inline-block">
          任何一方均应保留能够证明其遵守本条规定的真实准确的记录，经对方要求，应详细说明遵守本条规定的情况。
        </div>
      </div>
      <div class="indent-2em">
        <span style="position: absolute">8.3&ensp;</span>
        <div style="margin-left: 2em; display: inline-block">
          若由于任何一方违反本协议关于反商业贿赂的约定而给对方造成任何损失，包括但不限于商誉损失、政府调查、罚款等，该方应对守约方承担赔偿责任。
        </div>
      </div>
      <div class="gap-top-2em title2">8.&emsp;豁免及限制：</div>
      <div class="indent-2em">
        <span style="position: absolute">8.1&ensp;</span>
        <div style="margin-left: 2em; display: inline-block">
          本合同不包括维修、设备拆机、翻新、重装、迁移、搬动、保险费用等有偿服务项目（甲方如果要求乙方提供服务，则另行计费）。
        </div>
      </div>
      <div class="indent-2em">
        <span style="position: absolute">8.2&ensp;</span>
        <div style="margin-left: 2em; display: inline-block">
          本设备配置的外周设备，如工作站（思创、DELL、杏翔、Sun、思越星、Super Micro Compure采集工作站、青兰、Radworks、
          DragonView、Terra、惠普等)
          、激光相机、洗片机、高压注射器、病人监护仪、定位仪、稳压器等等，经双方协商，乙方将提供有偿维修服务。
        </div>
      </div>
      <div class="gap-top-2em title2">9.&emsp;违约责任：</div>
      <div class="indent-2em">
        <span style="position: absolute">9.1</span>
        <div style="margin-left: 2em; display: inline-block">
          由于公认的不可抗力事件造成的损失（如自然灾害、爆炸、房屋倒塌、暴乱、蓄意破坏、罢工、战争等），乙方不承担违约责任，但应及时通知甲方。
        </div>
      </div>
      <div class="indent-2em">
        <span style="position: absolute">9.2</span>
        <div style="margin-left: 2em; display: inline-block">
          在执行合同期间，乙方由于非甲方的原因而不再履行本合同，造成甲方损失的，乙方应当赔偿，该损失赔偿额最高不超过本合同签署时双方约定的甲方应支付给乙方的设备当年服务合同价款。
        </div>
      </div>
      <div class="indent-2em">
        <span style="position: absolute">9.3</span>
        <div style="margin-left: 2em; display: inline-block">
          在执行合同期间，甲方由于非乙方的原因而不再履行本合同，应付清至终止合同日期前应付的款项，并向乙方承担未付款项25%的违约责任；如果甲方因为合理原因取消或修改合同的，必须以书面形式提前向乙方提出申请，经双方协商确认后，合同取消或修改生效。对生效日之前乙方已提供的服务，甲方仍有义务支付相关款项。
        </div>
      </div>
      <div class="indent-2em">
        <span style="position: absolute">9.4</span>
        <div style="margin-left: 2em; display: inline-block">
          在合同有效期内，未经乙方认可，甲方或甲方安排其他第三方人员擅自维修，移机，保养及更换第三方零备件及耗材，乙方有权解除合同且不承担该设备的后续服务责任。
        </div>
      </div>
      <div class="indent-2em">
        <span style="position: absolute">9.5</span>
        <div style="margin-left: 2em; display: inline-block">
          在合同有限期内，由于非乙方维修、保养、检测和维护等原因引发的医疗事故纠纷由甲方自行承担相应的事故责任。
        </div>
      </div>
      <div class="indent-2em">
        <span style="position: absolute">9.6</span>
        <div style="margin-left: 2em; display: inline-block">
          在合同有效期内，甲方未按期支付费用的，甲方每日按照应付未付款项的万分之五向乙方支付违约金，甲方逾期支付满30天的，乙方有权立即解除本合同，甲方应付清至解除合同日期前应付的款项及按照9.3条的约定承担违约责任，并对乙方因此遭受的损失亦应承担赔偿责任（包括但不限于诉讼费、合理的律师费等）。
        </div>
      </div>
      <div class="gap-top-2em title2">10.&emsp;争议解决及法律适用：</div>
      <div class="indent-2em">双方应本着友好协商的原则解决争议。如协商不成，应提交乙方主要经营地法院诉讼解决。</div>
      <div class="gap-top-2em title2">11.&emsp;补充协议事宜：</div>
      <div class="indent-2em">
        <span style="position: absolute">11.1</span>
        <div style="margin-left: 2.5em; display: inline-block">
          本合同项下资产管理所涉及设备如以乙方驻场后实际排查为准，实际排查后设备情况与本合同附件不一致的，双方应在排查完成后5个工作日内对设备情况予以确认，并在2周内签署补充协议。
        </div>
      </div>
      <div class="indent-2em">
        <span style="position: absolute">11.2</span>
        <div style="margin-left: 2.5em; display: inline-block">
          合同履行期间，甲方按照规定报废设备、新出保设备或新增设备且尚未进行价款核算的，则双方根据实际核查的设备情况及双方另行协商确认的价款及时签署补充协议调整价款、设备清单；支付方式不变，只调整当期需付款金额。
        </div>
      </div>
      <div class="indent-2em">
        <span style="position: absolute">11.3</span>
        <div style="margin-left: 2.5em; display: inline-block">
          除上述情况外，补充协议不应涉及本合同其他约定的更改。补充协议中其他约定与本合同不 一致的，以本合同为准。
        </div>
      </div>
      <div class="gap-top-2em title2">12.&emsp;其他：</div>
      <div class="indent-2em">
        本合同经双方签字盖章生效，一式两份，甲方执一份，乙方执一份，双方所执签字盖章合同具有同等法律效力。
      </div>
      <div class="indent-2em">合同附件清单：（可删除没有的部分）。</div>
      <div v-if="!contractData.attachmentList.length" class="indent-2em">无</div>
      <div v-else v-for="(annex, index) in contractData.attachmentList" class="indent-2em" :key="`attach${index}`">
        {{ `${index + 1}、${annex.businessTypeDesc || '/'}` }}
      </div>
      <table border="1" class="gap-top-2em dashed whole-node">
        <tr height="150">
          <td class="title2">
            甲&emsp;&emsp;方：
            <br />
            （盖章）
          </td>
          <td></td>
          <td class="title2">
            乙&emsp;&emsp;方：
            <br />
            （盖章）
          </td>
          <td></td>
        </tr>
        <tr height="150">
          <td class="title2">
            法定代表人或授权代表：
            <br />
            （签字）
          </td>
          <td></td>
          <td class="title2">
            法定代表人或授权代表：
            <br />
            （签字）
          </td>
          <td></td>
        </tr>
        <tr height="150">
          <td class="title2">日期：</td>
          <td></td>
          <td class="title2">日期：</td>
          <td></td>
        </tr>
      </table>
    </div>
    <div style="text-align: center">
      <a-button @click="handlePrint">打印</a-button>
    </div>
  </div>
</template>
<script>
import { numberTranToUpperCN } from '@/utils/chinesePrice';

export default {
  name: 'HosipitalAssetContract',
  props: {
    contractData: {
      type: Object,
      default: () => ({}),
    },
  },
  data () {
    return {
      numberTranToUpperCN,
    };
  },
  methods: {
    handlePrint () {
      const style
        = '@page {margin:6mm 20mm;size: A4;} @charset "UTF-8";body {font-family: "宋体";}.a4 {width: 210mm;background-color: #fff;}.a4 .page-header {display: flex;justify-content: space-between;align-items: flex-end;font-size: 12px;padding-bottom: 6px;border-bottom: 1px solid #000;}.a4 .page-header img {width: 62%;}.a4 .page-header .company {display: inline-block;width: 16em;color: #808080;}.a4 div {line-height: 2em;}.a4 .gap-top-1em {margin-top: 1em;}.a4 .gap-top-2em {margin-top: 2em;}.a4 .title2 {font-weight: bolder;}.a4 .indent-2em {margin-left: 2em;}.a4 .title1 {text-align: center;line-height: 60px;font-size: 22px;letter-spacing: .5em;}.a4 table {width: 99%;table-layout: fixed;border-collapse: collapse;}.a4 table tr {page-break-inside: avoid;}.a4 table th {padding: 8px;font-weight: 500;}.a4 table td {padding: 8px;word-break: break-all;}.a4 table.dashed {border: 1px dashed #c8c8c8;}.a4 table.dashed td {border: 1px dashed #c8c8c8;}';

      this.$print({
        printable: 'printPaperRef',
        type: 'html',
        targetStyles: ['*'],
        style,
        scanStyles: false,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.a4 {
  width: 210mm;
  background-color: #fff;

  .page-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    font-size: 12px;
    padding-bottom: 6px;
    border-bottom: 1px solid #000;

    img {
      width: 62%;
    }

    .company {
      display: inline-block;
      width: 16em;
      color: #808080;
    }
  }

  div {
    line-height: 2em;
  }

  .gap-top-1em {
    margin-top: 1em;
  }

  .gap-top-2em {
    margin-top: 2em;
  }

  .title2 {
    font-weight: bolder;
  }

  .indent-2em {
    margin-left: 2em;
  }

  .text-indent-2em {
    text-indent: 2em;
  }

  .title1 {
    text-align: center;
    line-height: 32px;
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 0.5em;
  }

  table {
    width: 99%;
    table-layout: fixed;
    border-collapse: collapse;

    tr {
      page-break-inside: avoid;
    }

    th {
      padding: 8px;
      font-weight: 500;
    }

    td {
      padding: 8px;
      word-break: break-all;
    }
  }

  table.dashed {
    border: 1px dashed #c8c8c8;

    td {
      border: 1px dashed #c8c8c8;
    }
  }
}
</style>
